import React from "react";
import { IoCarSportOutline, IoCarOutline } from "react-icons/io5";
import { PiVan } from "react-icons/pi";


function Baliky() {
    return (
        <div>
        <section className="sluzby" id="sluzby">
            <h2 className="heading">Naše <span>Služby</span></h2>
            <div className="sluzby-container">

                <div className="sluzby-box box1">
                    <IoCarOutline size={65}/>
                    <h3>Malé Auto</h3>
                    <h2 className="typ-auta">(Škoda Fabia, Hyundai i20)</h2>
                    <a href="#kontakt" className="btn">40€</a>
                </div>
                
                <div className="sluzby-box box2">
                    <IoCarSportOutline size={65}/>
                    <h3>Veľké Auto</h3>
                    <h2 className="typ-auta">(Škoda Octavia, Kodiaq)</h2>
                    <a href="#kontakt" className="btn">60€</a>
                </div>
                
                <div className="sluzby-box box3">
                    <PiVan size={65}/>
                    <h3>Služobné auto</h3>
                    <h2 className="typ-auta">(Peugeot Boxer)</h2>
                    <a href="#kontakt" className="btn" >80€</a>
                </div>

            </div>
        </section>
    </div>
    )
}

export default Baliky;