import React from "react";
import Typed from "typed.js";
import { useEffect, useRef } from "react";

function Domov() {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current , {strings: ["Bratislava", " Pezinok", "Senec", "Malacky"],
        typeSpeed: 70,
        backSpeed: 80,
        backDelay: 600,
        loop: true
    });
    return () => {
        typed.destroy();
    };
    }, [])
    
    return (
        <section className="domov" id="domov">
            <div className="domov-content">
                <h3>Profesionálny</h3>
                <h1>Mobilný auto-detailing</h1>
                <h3>V okrese <span className="multiple-text" ref={el}></span></h3>
                <p>Vôňu nového auta privezieme až ku Vám domov</p>
                <div className="social-media">
                    <a href="https://www.facebook.com/profile.php?id=100091977361424" target="_blank" rel="noreferrer"><i className='bx bxl-facebook-square'></i></a>
                    <a href="https://www.instagram.com/crystal_bratislava" target="_blank" rel="noreferrer"><i className='bx bxl-instagram'></i></a>
                </div>
                <a href="#kontakt" className="btn">Kontaktuj Nás</a>
            </div>
            <div className="domov-img">
                <img src={require("./files/logo-domov.png")} alt="logo-crystall"/>
            </div>
        </section>
    )
}

export default Domov;