import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Resend } from 'resend';

function Kontakt() {
    const [capVal, setCapVal] = useState(null);
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(form.current);
        const data = {
            from: "kontakt@crystaldetailing.sk", // nahradiť vlastnou emailovou adresou
            to: formData.get("user_email"),
            subject: `Nová objednávka od ${formData.get("user_name")}`,
            html: `
                <h1>Objednávka</h1>
                <p>Meno: ${formData.get("user_name")}</p>
                <p>Email: ${formData.get("user_email")}</p>
                <p>Adresa: ${formData.get("user_adress")}</p>
                <p>Telefón: ${formData.get("user_phone")}</p>
                <p>Balík: ${formData.get("Balik_GOLD")}</p>
                <p>Doplnková služba: ${formData.get("user_dopln")}</p>
                <p>Správa: ${formData.get("user_message")}</p>
            `,
        };

        try {
            const resend = new Resend("re_iby2VhPN_E9SCMhYcfDvYPCe3bEgqn15y"); // nahraď svojím API kľúčom
            await resend.emails.send(data);
            alert("Vaša objednávka bola úspešne odoslaná");
        } catch (error) {
            console.error("Chyba pri odosielaní:", error);
            alert("Nepodarilo sa odoslať správu, skúste znova.");
        } finally {
            setLoading(false);
            e.target.reset();
        }
    };

    return (
        <section className="kontakt" id="kontakt">
            <h2 className="heading">Kontaktuj <span>Nás!</span></h2>
            <form ref={form} onSubmit={sendEmail}>
                <div className="input-box">
                    <input type="text" placeholder="Meno a priezvisko" id="name" name="user_name"/>
                    <input type="email" placeholder="Emailová adresa" id="email" name="user_email" required/>
                </div>
                <div className="input-box">
                    <input type="text" placeholder="PSČ, adresa a číslo domu" id="adresa" name="user_adress"/>
                    <input type="tel" id="phone" name="user_phone" placeholder="0905 123 456" required/>
                </div>
                <div className="input-box">
                    <select id="balik" name="Balik_GOLD" form="balikList" required>
                        <option value="Balík GOLD">Malé Auto</option>
                        <option value="Balík PLATINUM">Veľké Auto</option>
                        <option value="Balík DIAMOND">Služobné Auto</option>
                    </select>
                    <select id="dopln" name="user_dopln" form="doplnList">
                        <option value="nič">Žiadna doplnková služba</option>
                        <option value="Leštenie svetlometov">Leštenie svetlometov</option>
                        <option value="Detailing kolies a diskov">Detailing kolies a diskov</option>
                        <option value="Definfekcia a čistenie parou">Dezinfekcia a čistenie parou</option>
                        <option value="Oživenie čiernych plastov">Oživenie čiernych plastov</option>
                    </select>
                </div>
                <textarea name="user_message" id="message" cols="30" rows="10" placeholder="Vaša správa, špeciálne požiadavky, etc."></textarea>
                <ReCAPTCHA sitekey="6Ldi1hkmAAAAAOek1F1mYkCN3wEv7EQFyyaIetDf" onChange={(val) => setCapVal(val)} required/>
                <button disabled={!capVal || loading} type="submit" className="btn">
                    {loading ? "Odosielam..." : "Odoslať správu"}
                </button>
            </form>
        </section>
    );
}

export default Kontakt;